
import { Vue, Component } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import SearchInput from "@/components/widgets/common/SearchInput.vue"
import RecordSummaryCardGroup from "@/components/content/RecordSummaryCardGroup.vue"
import RecordSummaryCard from "@/components/content/RecordSummaryCard.vue"
import { FilterType } from "@/components/widgets/common/FilterBar.vue"
import TaskList from "@/components/client/Tasks/TaskList.vue"
import { ClientsTaskQuery, ClientsTaskQueryQuery, Task } from "@/gql"
import { PageState } from "@/types"
import { NetworkStatus, ApolloQueryResult } from "@apollo/client/core"
import { useAccountContext } from "@/hooks/useAccountContext"

enum TaskTypes {
  All = "All",
  Approval = "Approval",
  Contract = "Contract",
  Feedback = "Feedback",
}

@Component({
  components: {
    AppPage,
    SearchInput,
    RecordSummaryCardGroup,
    RecordSummaryCard,
    TaskList,
  },
  apollo: {
    tasks: {
      query: ClientsTaskQuery,
      variables() {
        return {
          id: this.clientId,
        }
      },
      result(result) {
        this.onTasksResult(result)
      },
      error(error) {
        if (error.graphQLErrors) {
          this.loading = false
          this.loadingError = true
          this.pageState = PageState.Error
        } else if (error.networkError) {
          this.networkError = true
        }
      },
      update(data) {
        return data?.myClient.tasks || []
      },
    },
  },
})
export default class Tasks extends Vue {
  tasks: Task[] = []
  tab = null
  filter: FilterType = {}
  selectedTaskTab: any | null = null

  get selectedTabKey() {
    const mapping: Record<number, TaskTypes> = {
      0: TaskTypes.All,
      1: TaskTypes.Approval,
      2: TaskTypes.Contract,
      3: TaskTypes.Feedback,
    }

    return mapping[this.selectedTaskTab || 0]
  }

  get clientId() {
    const { client } = useAccountContext()
    return client?.prn
  }
  TaskTypes = TaskTypes
  PageState = PageState
  pageState = PageState.Loaded
  loading = true
  loadingError = false
  networkError = false

  onTasksResult(result: ApolloQueryResult<ClientsTaskQueryQuery>) {
    if (result.networkStatus === NetworkStatus.ready) {
      this.pageState = PageState.Loaded

      this.loading = false

      if (result?.data?.myClient?.tasks?.length) {
        this.pageState = PageState.Empty
      }
    } else {
      this.pageState = PageState.Empty
    }
  }

  get groupedTasks(): { [taskType: string]: Task[] } {
    const grouped = this.tasks.reduce((groupedTasks, task) => {
      if (!groupedTasks[task.taskableType!]) groupedTasks[task.taskableType!] = []
      groupedTasks[task.taskableType!].push(task)
      return groupedTasks
    }, {} as { [taskType: string]: Task[] })

    grouped["All"] = this.tasks
    return grouped
  }

  getRenderedTasks(key: string) {
    return this.groupedTasks[key] || []
  }
}
