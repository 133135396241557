var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('filter-bar',{ref:"filterBar",attrs:{"dense":true,"flat":true,"isBordered":true,"sticky":false,"reverse":false,"searchFilters":[
      {
        field: 'search',
        label: 'tasks',
        expanded: true,
      },
    ],"listFilters":[
      {
        field: 'priority',
        label: 'Priority',
        filters: {
          low: 'Low priority',
          medium: 'Medium priority',
          high: 'High priority',
        },
      },
    ]},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('section',{staticClass:"main-content mt-4"},[_c('RecordSummaryCardGroup',{attrs:{"filter":_vm.filter},scopedSlots:_vm._u([{key:"loading-panel",fn:function(){return [_c('div',{staticClass:"text-center grey--text text--darken-2"},[(!_vm.taskList.length)?_c('div',[_c('FeedBackMessage',{staticClass:"outlined-card box-shadow",attrs:{"resource":"tasks","preset":"emptyState","icon":"la-tasks","customMessage":"Tasks assigned to you will show here"}})],1):_vm._e()])]},proxy:true}])},_vm._l((_vm.taskList),function(task){return _c('div',{key:task.id},[_c('RecordSummaryCard',{staticClass:"mb-2",attrs:{"title":task.title,"subtitle":task.taskableName,"description":task.description,"tags":_vm.getTags(task.createdAt, task.deadline),"status":task.priority},scopedSlots:_vm._u([{key:"time-ago",fn:function(){return [_c('div',{staticClass:"mt-1"},[_c('span',{staticStyle:{"font-weight":"400","font-size":"14px","line-height":"21px","color":"#8c8c8c"}},[_vm._v(" "+_vm._s(_vm.timeAgo(new Date(task.createdAt)))+" ")])])]},proxy:true},{key:"tools",fn:function(){return [(task.metadata.action)?_c('secondary-button',{staticClass:"mr-2",attrs:{"small":"","outlined":"","to":_vm.getActionLink(task.metadata.action.to)}},[(false)?_c('Icon',{attrs:{"name":"pen-2","size":"16"}}):_vm._e(),_c('span',{staticClass:"ml-1 font-size-14"},[_vm._v(_vm._s(task.metadata.action.label))])],1):_vm._e()]},proxy:true}],null,true)})],1)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }