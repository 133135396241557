type SearchType<T> = {
  items: T[]
  search: string
  fields: string[]
}

function fullTextSearch(text: string, search: string) {
  const pattern = new RegExp(`.*${search}.*`, "i")
  return pattern.test(text)
}

export function useSearch<T>({ items, search, fields }: SearchType<T>): T[] {
  return items.filter((item: any) => {
    return fields.some((field: string) => {
      const fieldParts = field.split(".")
      let value: any = item

      for (const part of fieldParts) {
        if (value == null) return false
        value = value[part]
      }

      return fullTextSearch(value, search)
    })
  })
}
