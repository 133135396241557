
import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import SearchInput from "@/components/widgets/common/SearchInput.vue"
import moment from "moment"
import RecordSummaryCardGroup from "@/components/content/RecordSummaryCardGroup.vue"
import RecordSummaryCard from "@/components/content/RecordSummaryCard.vue"
import { FilterType } from "@/components/widgets/common/FilterBar.vue"
import Icon from "@/components/widgets/icons/Icon.vue"
import { Task } from "@/gql"
import { useUrl } from "@/hooks/useUrl"
import { useTimeAgo } from "@/hooks/useTimeAgo"
import { useSearch } from "@/hooks/useSearch"

@Component({
  components: {
    AppPage,
    SearchInput,
    RecordSummaryCardGroup,
    RecordSummaryCard,
    Icon,
  },
})
export default class TaskList extends Vue {
  @Prop({ required: true }) tasks!: Task[]

  taskList: Task[] = []
  filter: FilterType = {}

  timeAgo(date: string) {
    return useTimeAgo(date)
  }

  getTags(createdAt: string, dueDate: string) {
    return [
      {
        name: "Created: " + moment(createdAt).format("Do MMMM, YYYY"),
        icon: "la-calendar",
      },

      {
        name: "Due: " + moment(dueDate).format("Do MMMM, YYYY"),
        icon: "la-calendar-check",
      },
    ]
  }

  getActionLink(to: { name: string; params: { id: number } }): string {
    const { href } = useUrl(to.name, { id: to.params.id })
    return href
  }

  fullTextSearch(text: string, search: string) {
    const pattern = new RegExp(`.*${search}.*`, "i")
    return pattern.test(text)
  }

  @Watch("filter.search")
  onTasksSearchChange() {
    if (!this.tasks) return

    if (!this.filter.search) {
      this.taskList = this.tasks
    } else {
      this.taskList = this.searchTasks(this.filter.search)
    }
  }

  searchTasks(searchTerm: string) {
    return useSearch({ items: this.tasks, search: searchTerm, fields: ["title", "description"] })
  }

  @Watch("filter.priority")
  onTaskStateChange() {
    if (!this.tasks) return

    if (!this.filter.priority) {
      this.taskList = this.tasks
    } else {
      this.taskList = this.tasks.filter((task: Task) => {
        return task.priority === this.filter.priority
      })
    }
  }

  onSetTaskState() {
    this.taskList = this.tasks
  }

  mounted() {
    if (this.tasks) {
      this.onSetTaskState()
    }
  }
}
